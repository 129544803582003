import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/providers/AuthProvider";

interface GuardProps {
    children: React.ReactNode;
}

const SubscriptionGuard = ({ children }: GuardProps) => {
    console.log("Executing SubscriptionGuard");

    const { subscription, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Executing SubscriptionGuard useEffect");

        if (!loading && (!subscription || (subscription.status !== "active" && subscription.status !== "trialing"))) {
                console.log("Navigating to /checkout due to subscription not being active");
                navigate("/checkout");
        }
    }, [subscription, loading, navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return subscription && (subscription.status === "active" || subscription.status === "trialing") ? <>{children}</> : null;
};

export default SubscriptionGuard;
