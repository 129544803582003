import React from "react";

interface StatsComponentProps {
    statsData: Record<string, number>;
}

interface StatItem {
    name: string;
    stat: string;
    prefix: string;
    suffix: string;
    pill?: string; // Optional pill property
}

const StatsComponent: React.FC<StatsComponentProps> = ({ statsData }) => {
    

    const getStatClass = (value: number) => {
        if (isNaN(value)) {
            return 'text-gray-900 dark:text-white';
        }
        return value >= 0 ? 'text-green-500' : 'text-red-500';
    };

    const stats: StatItem[] = [
        {
            name: "Total Profit",
            stat: statsData.totalProfit.toFixed(2),
            prefix: "£",
            suffix: ""
        },
        {
            name: "Total Sales",
            stat: statsData.totalSales.toFixed(2),
            prefix: "£",
            suffix: ""
        },
        {
            name: "Return on Investment",
            stat: statsData.returnOnInvestment.toFixed(2),
            prefix: "",
            suffix: "%"
        },
        {
            name: "Profit Per Hour",
            stat: "N/A",
            prefix: "",
            suffix: "",
            pill: "Coming Soon" // Add pill here
        },
    ];

    const stats2: StatItem[] = [
        {
            name: "New Listings",
            stat: statsData.newListings.toString(),
            prefix: "",
            suffix: ""
        },
        {
            name: "Products Sold",
            stat: statsData.productsSold.toString(),
            prefix: "",
            suffix: ""
        },
        {
            name: "Avg. Transaction Value",
            stat: statsData.avgTransactionValue.toFixed(2),
            prefix: "£",
            suffix: ""
        },
        {
            name: "Avg. Time to Sell",
            stat: statsData.avgTimeToSell.toFixed(2),
            prefix: "",
            suffix: " days"
        },
    ];

    return (
        <div>
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
                {stats.map((item) => (
                    <div
                        key={item.name}
                        className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                    >
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            {item.name}
                        </dt>
                        <dd className={`mt-1 text-3xl font-semibold tracking-tight ${getStatClass(parseFloat(item.stat))}`}>
                            {item.prefix}{item.stat}{item.suffix}
                        </dd>
                        {item.pill && (
                            <span className="absolute top-4 right-4 inline-flex items-center rounded-full bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                                {item.pill}
                            </span>
                        )}
                    </div>
                ))}
            </dl>
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
                {stats2.map((item) => (
                    <div
                        key={item.name}
                        className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                    >
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            {item.name}
                        </dt>
                        <dd className={`mt-1 text-3xl font-semibold tracking-tight ${getStatClass(parseFloat(item.stat))}`}>
                            {item.prefix}{item.stat}{item.suffix}
                        </dd>
                        {item.pill && (
                            <span className="absolute top-4 right-4 inline-flex items-center rounded-full bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                                {item.pill}
                            </span>
                        )}
                    </div>
                ))}
            </dl>
        </div>
    );
}

export default StatsComponent;
