import React, { useState, useEffect, useRef } from "react";
import { format, parseISO, startOfDay, endOfDay, set } from "date-fns";
import {
    GET_EBAY_ITEM_TAGS_QUERY,
    GET_EBAY_ITEM_CATEGORIES_QUERY,
    GET_EBAY_ITEM_BRANDS_QUERY,
    GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
} from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import { useQuery } from "@apollo/client";

interface DateRangePickerProps {
    initialStartDate: Date;
    initialEndDate: Date;
    setFilters: (name: string, value: any) => void;
    applyFilters: () => void;
    setStatsFilters: (name: string, value: any) => void;
    applyStatsFilters: () => void;
    resetStatsFilters: () => void;
    resetFilters: () => void;
    filters: Record<string, any>;
    showFilters: boolean;
    toggleFilters: () => void;
}

const presets = [
    { text: "Today", start: startOfDay(new Date()), end: endOfDay(new Date()) },
    {
        text: "Yesterday",
        start: startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        end: endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
    },
    {
        text: "Last 7 Days",
        start: startOfDay(new Date(new Date().setDate(new Date().getDate() - 7))),
        end: endOfDay(new Date()),
    },
    {
        text: "Last 30 Days",
        start: startOfDay(new Date(new Date().setDate(new Date().getDate() - 30))),
        end: endOfDay(new Date()),
    },
    {
        text: "Last 3 Months",
        start: startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 3))),
        end: endOfDay(new Date()),
    },
    {
        text: "Last 6 Months",
        start: startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 6))),
        end: endOfDay(new Date()),
    },
    {
        text: "This Month",
        start: startOfDay(new Date(new Date().setDate(1))),
        end: endOfDay(new Date()),
    },
    {
        text: "Last Month",
        start: startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 1, 1))),
        end: endOfDay(new Date(new Date().setMonth(new Date().getMonth(), 0))),
    },
    {
        text: "This Year",
        start: startOfDay(new Date(new Date().setMonth(0, 1))),
        end: endOfDay(new Date()),
    },
    {
        text: "Last Year",
        start: startOfDay(new Date(new Date().setFullYear(new Date().getFullYear() - 1, 0, 1))),
        end: endOfDay(new Date(new Date().setFullYear(new Date().getFullYear() - 1, 11, 31))),
    },
    {
        text: "Last 5 Years",
        start: startOfDay(new Date(new Date().setFullYear(new Date().getFullYear() - 5))),
        end: endOfDay(new Date()),
    },
    {
        text: "All Time",
        start: startOfDay(new Date(1970, 0, 1)),
        end: endOfDay(new Date()),
    },
];

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
    initialStartDate,
    initialEndDate,
    setFilters,
    applyFilters,
    setStatsFilters,
    applyStatsFilters,
    resetFilters,
    filters,
    showFilters,
    toggleFilters,
}) => {
    const { data: tagsData } = useQuery(GET_EBAY_ITEM_TAGS_QUERY);
    const { data: categoriesData } = useQuery(GET_EBAY_ITEM_CATEGORIES_QUERY);
    const { data: brandsData } = useQuery(GET_EBAY_ITEM_BRANDS_QUERY);
    const { data: sourceLocationsData } = useQuery(GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY);

    const [startDate, setStartDate] = useState<Date>(initialStartDate);
    const [endDate, setEndDate] = useState<Date>(initialEndDate);
    const [tempStartDate, setTempStartDate] = useState<string>(format(initialStartDate, "yyyy-MM-dd"));
    const [tempEndDate, setTempEndDate] = useState<string>(format(initialEndDate, "yyyy-MM-dd"));
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleApplyClick = () => {
        const parsedStartDate = parseISO(tempStartDate);
        const parsedEndDate = parseISO(tempEndDate);

        const formattedStartDate = startOfDay(parsedStartDate).toISOString();
        const formattedEndDate = endOfDay(parsedEndDate).toISOString();

        setStartDate(parsedStartDate);
        setEndDate(parsedEndDate);
        setFilters("ebaySaleSoldAtGte", formattedStartDate);
        setFilters("ebaySaleSoldAtLte", formattedEndDate);
        setStatsFilters("ebaySaleSoldAtGte", formattedStartDate);
        setStatsFilters("ebaySaleSoldAtLte", formattedEndDate);
        setStatsFilters("ebayItemListedAtGte", formattedStartDate);
        setStatsFilters("ebayItemListedAtLte", formattedEndDate);
        applyStatsFilters();
        applyFilters();
        setIsOpen(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilters(name, value === "" ? null : value);
        setStatsFilters(name, value === "" ? null : value);
    };

    const handlePresetClick = (preset: { start: Date; end: Date }) => {
        setTempStartDate(format(preset.start, "yyyy-MM-dd"));
        setTempEndDate(format(preset.end, "yyyy-MM-dd"));
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const handleBlur = (
        e: React.FocusEvent<HTMLInputElement>,
        setDate: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const parsedDate = parseISO(e.target.value);
        if (!isNaN(parsedDate.getTime())) {
            setDate(e.target.value);
        } else {
            setDate(format(new Date(), "yyyy-MM-dd"));
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
        <div className="relative inline-block w-full" ref={ref}>
            <div className="flex justify-between items-center">
            <button
                className="inline-flex items-center justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                onClick={() => setIsOpen(!isOpen)}
                style={{ minWidth: "250px" }}
            >
                {format(startDate, "dd/MM/yyyy")} - {format(endDate, "dd/MM/yyyy")}
                <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>

            <div className="flex space-x-2 ml-4">
                <button
                    onClick={resetFilters}
                    className="px-4 py-2 bg-gray-600 text-white dark:text-gray-900 dark:bg-gray-300 rounded-lg"
                >
                    Reset Filters
                </button>
                <button
                    onClick={toggleFilters}
                    className="px-4 py-2 bg-white text-black border-black border dark:border-white dark:text-white dark:bg-gray-700 rounded-lg"
                >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                </button>
            </div>
            </div>

            {isOpen && (
                <div className="absolute left-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-900">
                    <div className="px-4 py-2">
                        <div className="date-picker mb-4 grid grid-cols-2 gap-2">
                            <div className="flex flex-col">
                                <label className="mb-1 text-gray-700 dark:text-gray-300">
                                    Start Date
                                </label>
                                <input
                                    type="date"
                                    value={tempStartDate}
                                    onChange={(e) => setTempStartDate(e.target.value)}
                                    onBlur={(e) => handleBlur(e, setTempStartDate)}
                                    className="rounded-lg border p-2 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="mb-1 text-gray-700 dark:text-gray-300">
                                    End Date
                                </label>
                                <input
                                    type="date"
                                    value={tempEndDate}
                                    onChange={(e) => setTempEndDate(e.target.value)}
                                    onBlur={(e) => handleBlur(e, setTempEndDate)}
                                    className="rounded-lg border p-2 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                />
                            </div>
                        </div>
                        <div className="presets mb-4 grid grid-cols-2 gap-2">
                            {presets.map((preset) => (
                                <button
                                    key={preset.text}
                                    onClick={() => handlePresetClick(preset)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded-lg px-4 py-2 text-gray-700 transition dark:text-white"
                                >
                                    {preset.text}
                                </button>
                            ))}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={handleApplyClick}
                                className="rounded-lg bg-green-500 px-4 py-2 text-white transition hover:bg-green-600"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>

            {showFilters && (
                <div className="mt-4 px-2 sm:px-2 lg:px-1 pb-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        <select
                            name="tags"
                            value={filters.tags || ""}
                            onChange={handleInputChange}
                            className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                        >
                            <option value="">All Tags</option>
                            {tagsData?.ebayItemTags.map((tag: any) => (
                                <option key={tag.id} value={tag.id}>
                                    {tag.name}
                                </option>
                            ))}
                        </select>
                        <select
                            name="sourceLocationId"
                            value={filters.sourceLocationId || ""}
                            onChange={handleInputChange}
                            className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                        >
                            <option value="">All Source Locations</option>
                            {sourceLocationsData?.sourceLocations.map((location: any) => (
                                <option key={location.id} value={location.id}>
                                    {location.name}
                                </option>
                            ))}
                        </select>
                        <input
                            type="number"
                            name="listedPriceLte"
                            placeholder="Max Listed Price"
                            defaultValue={filters.listedPriceLte || ""}
                            onBlur={handleInputChange}
                            className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                        />
                        <input
                            type="number"
                            name="listedPriceGte"
                            placeholder="Min Listed Price"
                            defaultValue={filters.listedPriceGte || ""}
                            onBlur={handleInputChange}
                            className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                        />
                        <select
                            name="itemCategoryId"
                            value={filters.itemCategoryId || ""}
                            onChange={handleInputChange}
                            className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                        >
                            <option value="">All Categories</option>
                            {categoriesData?.itemCategories.map((category: any) => (
                                <option key={category.id} value={category.id}>
                                    {category.categoryName}
                                </option>
                            ))}
                        </select>
                        <select
                            name="itemBrandId"
                            value={filters.itemBrandId || ""}
                            onChange={handleInputChange}
                            className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                        >
                            <option value="">All Brands</option>
                            {brandsData?.brands.map((brand: any) => (
                                <option key={brand.id} value={brand.id}>
                                    {brand.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomDateRangePicker;
