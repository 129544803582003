import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth";
import LogoComponent from "@/components/logo/LogoComponent";

export default function LoginPage() {
    const navigate = useNavigate();
    const { user, loginUser, loading, ebayAccount } = useAuth(); // Fetch the necessary context data and functions
    const [isRedirecting, setIsRedirecting] = useState(false);

    // Check authentication and redirect if the user is already logged in
    useEffect(() => {
        if (user && !isRedirecting) {
            setIsRedirecting(true); // Prevent further redirections
            if (ebayAccount && ebayAccount.isActive) {
                navigate("/dashboard");
            } else {
                navigate("/ebay-auth");
            }
        }
    }, [user, navigate, ebayAccount, isRedirecting]);

    // Handle login form submission
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const email = e.currentTarget.email.value;
        const password = e.currentTarget.password.value;

        try {
            await loginUser(email, password); // Perform login
        } catch (error) {
            console.error("Failed to login", error);
            // Handle login error (display message, etc.)
        }
    };

    // If loading, show a loading indicator
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleLogin}>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 dark:text-white"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium leading-6 dark:text-white"
                            >
                                Password
                            </label>
                            <div className="text-sm">
                                <a
                                    href="#"
                                    className="font-semibold text-brand hover:text-brand"
                                >
                                    Forgot password?
                                </a>
                            </div>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                        >
                            Sign in
                        </button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm dark:text-gray-400">
                    Not a member?{" "}
                    <Link
                        to="/signup"
                        className="font-semibold leading-6 text-brand hover:text-brand"
                    >
                        Sign up here
                    </Link>
                </p>
            </div>
        </div>
    );
}
