import { Navigate, createBrowserRouter } from "react-router-dom";
import AuthGuard from "@/guards/AuthGuard";
import EmailVerifiedGuard from "@/guards/EmailVerifiedGuard";
import EbayAccountLinkedGuard from "@/guards/EbayAccountLinkedGuard";
import SubscriptionGuard from "@/guards/SubscriptionGuard";
import InitialisedGuard from "@/guards/InitialisedGuard";

import DashboardLayout from "@/layouts/(dashboard)/DashboardLayout";

// Auth Pages
import LoginPage from "../pages/(auth)/login/LoginPage";
import SignupPage from "@/pages/(auth)/signup/SignupPage";
import LogoutPage from "@/pages/(auth)/logout/LogoutPage";
import AwaitingVerificationPage from "@/pages/(auth)/email-verification/AwaitingVerificationPage";
import VerifyEmailPage from "@/pages/(auth)/email-verification/VerifyEmailPage";
// OAuth Pages
import EbayAuthPage from "@/pages/(oauth)/ebay-auth/EbayAuthPage";
import EbayCallbackPage from "@/pages/(oauth)/ebay-callback/EbayCallbackPage";
// Payment Pages
import CheckoutPage from "@/pages/(payments)/CheckoutPage";
// Stripe Pages
import CancelPage from "@/pages/(payments)/CancelPage";
import SuccessPage from "@/pages/(payments)/SuccessPage";
// Dashboard Pages
import DashboardPage from "@/pages/(dashboard)/DashboardPage";
// Inventory Dashboard Pages
import ActiveProductsPage from "@/pages/(dashboard)/inventory/active-products/ActiveProductsPage";
import UnlistedProductsPage from "@/pages/(dashboard)/inventory/unlisted-products/UnlistedProducts";
import MissingInventoryDataPage from "@/pages/(dashboard)/inventory/missing-data/MissingInventoryDataPage";
// Sold Dashboard Pages
import AllSoldPage from "@/pages/(dashboard)/sold/all-sold/AllSoldPage";
import MissingDataEbaySalesPage from "@/pages/(dashboard)/sold/missing-data/MissingDataEbaySalesPage";
import ReadyToShipEbaySalesPage from "@/pages/(dashboard)/sold/ready-to-ship/ReadyToShipEbaySalesPage";
import ShippedEbaySalesPage from "@/pages/(dashboard)/sold/shipped/ShippedEbaySalesPage";
// Insights Dashboard Pages
import InsightsPage from "@/pages/(dashboard)/insights/InsightsPage";
// Manage Dashboard Pages
import TagsPage from "@/pages/(dashboard)/manage/tags/TagsPage";
import SourceLocationsPage from "@/pages/(dashboard)/manage/source-locations/SourceLocationsPage";
// Settings Dashboard Pages
import AccountSettingsPage from "@/pages/(dashboard)/account/AccountSettingsPage";
// Product Page
import ProductPage from "@/pages/(dashboard)/products/EbayProductPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/signup",
        element: <SignupPage />,
    },
    {
        path: "/email-verification",
        element: (
            <AuthGuard>
                    <AwaitingVerificationPage />
            </AuthGuard>
        )
    },
    {
        path: "/verify-email/:token",
        element: <VerifyEmailPage />,
    },
    {
        path: "/ebay-auth",
        element: (
            <AuthGuard>
                <EmailVerifiedGuard>
                    <EbayAuthPage />
                </EmailVerifiedGuard>
            </AuthGuard>
        ),
    },
    {
        path: "/ebay-callback",
        element: (
            <AuthGuard>
                <EmailVerifiedGuard>
                    <EbayCallbackPage />
                </EmailVerifiedGuard>
            </AuthGuard>
        ),
    },
    {
        path: "/checkout",
        element: (
            <AuthGuard>
                <EmailVerifiedGuard>
                    <EbayAccountLinkedGuard>
                        <CheckoutPage />
                    </EbayAccountLinkedGuard>
                </EmailVerifiedGuard>
            </AuthGuard>
        ),
    },
    {
        path: "/stripe/cancel",
        element: (
            <AuthGuard>
                <EmailVerifiedGuard>
                    <EbayAccountLinkedGuard>
                        <SubscriptionGuard>
                            <CancelPage />
                        </SubscriptionGuard>
                    </EbayAccountLinkedGuard>
                </EmailVerifiedGuard>
            </AuthGuard>
        ),
    },
    {
        path: "/stripe/success",
        element: (
            <AuthGuard>
                <EmailVerifiedGuard>
                    <EbayAccountLinkedGuard>
                        <SubscriptionGuard>
                            <SuccessPage />
                        </SubscriptionGuard>
                    </EbayAccountLinkedGuard>
                </EmailVerifiedGuard>
            </AuthGuard>
        ),
    },
    {
        path: "/dashboard",
        element: (
            <AuthGuard>
                <EmailVerifiedGuard>
                    <EbayAccountLinkedGuard>
                        <SubscriptionGuard>
                            <InitialisedGuard>
                                <DashboardLayout />
                            </InitialisedGuard>
                        </SubscriptionGuard>
                    </EbayAccountLinkedGuard>
                </EmailVerifiedGuard>
            </AuthGuard>
        ),
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },
            {
                path: "inventory/active-products",
                element: <ActiveProductsPage />,
            },
            {
                path: "inventory/unlisted-products",
                element: <UnlistedProductsPage />,
            },
            {
                path: "inventory/missing-data",
                element: <MissingInventoryDataPage />,
            },
            {
                path: "sold/all-sold",
                element: <AllSoldPage />,
            },
            {
                path: "sold/missing-data",
                element: <MissingDataEbaySalesPage />,
            },
            {
                path: "sold/ready-to-ship",
                element: <ReadyToShipEbaySalesPage />,
            },
            {
                path: "sold/shipped",
                element: <ShippedEbaySalesPage />,
            },
            {
                path: "insights",
                element: <InsightsPage />,
            },
            {
                path: "account",
                element: <AccountSettingsPage />,
            },
            {
                path: "products/:product_id",
                element: <ProductPage />,
            },
            {
                path: "manage/tags",
                element: <TagsPage />,
            },
            {
                path: "manage/source-locations",
                element: <SourceLocationsPage />,
            }
        ],
    },
]);
