import React from "react";
import { Link } from "react-router-dom";
import { NavigationChild } from "@/types/globalTypes";
import { classNames } from "@/helpers/common/classNames";

interface NavItemProps {
    item: NavigationChild;
    isActive: boolean;
    disabled?: boolean;
}

export const SidebarNavItemChild: React.FC<NavItemProps> = ({
    item,
    isActive,
    disabled = false,
}) => {
    return (
        <li>
            <Link
                to={disabled ? "#" : item.href}
                onClick={(e) => disabled && e.preventDefault()}
                className={classNames(
                    isActive
                        ? "bg-brand dark:text-white"
                        : "hover:bg-brand dark:text-gray-200 dark:hover:text-white",
                    disabled
                        ? "cursor-not-allowed opacity-50 group flex items-center justify-between gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                        : "group flex items-center justify-between gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                )}
                aria-disabled={disabled}
                tabIndex={disabled ? -1 : 0} // Optional: Make the link unfocusable if disabled
            >
                <span className="flex items-center gap-x-3">{item.name}</span>
            </Link>
        </li>
    );
};
