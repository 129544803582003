import { gql } from "@apollo/client";

export const UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION = gql`
    mutation UpdateEbayAccountSettingsMutation(
        $matchPostagePriceWithCost: Boolean
    ) {
        updateEbayAccountSettings(
            matchPostagePriceWithCost: $matchPostagePriceWithCost
        ) {
            settings {
                id
                matchPostagePriceWithCost
            }
        }
    }
`;
