import React from "react";
import { useNavigate } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";

const CancelPage: React.FC = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate("/checkout");
  };

  const handleLearnMore = () => {
    window.location.href = "https://fliplytics.co.uk";
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <LogoComponent className="mx-auto h-20 w-auto" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
          Checkout Canceled
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md w-full">
          <p className="mb-6 text-center text-lg dark:text-white">
            It seems like you didn&apost complete your purchase. But don&apost worry, you can retry the checkout or learn more about our product.
          </p>

          <div className="flex justify-between mb-6">
            <button
              onClick={handleRetry}
              className="px-4 py-2 rounded-md focus:outline-none w-full mr-2 bg-brand text-white hover:bg-brand-light dark:bg-gray-700 dark:hover:bg-brand-light"
            >
              Retry Checkout
            </button>
            <button
              onClick={handleLearnMore}
              className="px-4 py-2 rounded-md focus:outline-none w-full ml-2 bg-gray-200 text-gray-900 hover:bg-gray-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
            >
              Learn More
            </button>
          </div>

          <p className="text-sm text-center dark:text-gray-400">
            Need help? <a href="https://fliplytics.co.uk/contact" className="font-semibold text-brand hover:text-brand-light">Contact our support team</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;
