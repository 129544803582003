import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth"; // Ensure the path is correct
import { Suspense } from "react";

const INITIATE_OAUTH_GET_ACCESS_TOKEN = gql`
    mutation InitiateOauthGetAccessToken($name: String!, $code: String!) {
        initiateOauthGetAccessToken(code: $code, name: $name) {
            success
            ebayAccount {
                id
                name
                isActive
            }
        }
    }
`;

export default function EbayCallbackPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { fetchUserData } = useAuth(); // Fetch the user data context function

    const [initiateOauthGetAccessToken] = useMutation(INITIATE_OAUTH_GET_ACCESS_TOKEN);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const handleOAuth = async () => {
            const code = searchParams.get("code");
            const name = searchParams.get("state"); // Assuming 'state' is used for the eBay account name

            if (!code) {
                setError("No authorization code provided.");
                return;
            }

            setLoading(true);
            try {
                const { data } = await initiateOauthGetAccessToken({
                    variables: { code, name },
                });

                if (data.initiateOauthGetAccessToken.success) {
                    await fetchUserData(); // Refresh user data after successfully linking eBay account
                    navigate("/checkout"); // Redirect to checkout after successful account linking
                } else {
                    setError("Failed to link eBay account. Please try again.");
                }
            } catch (error) {
                console.error("OAuth error:", error);
                setError("An error occurred during the OAuth process. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        handleOAuth();
    }, [fetchUserData, initiateOauthGetAccessToken, navigate, searchParams]);

    if (loading) return <div>Loading...</div>;

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div>
                {error ? (
                    <div className="text-red-600 text-center">
                        <h1>Error</h1>
                        <p>{error}</p>
                        <button onClick={() => navigate("/login")} className="mt-4">
                            Go to Login
                        </button>
                    </div>
                ) : (
                    <h1>Redirecting...</h1>
                )}
            </div>
        </Suspense>
    );
}
