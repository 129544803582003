import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
    GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    GET_EBAY_ITEM_BRANDS_QUERY,
    GET_EBAY_ITEM_CATEGORIES_QUERY,
    GET_EBAY_ITEM_TAGS_QUERY,
} from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import useInventoryMissingDataEbayItems from "@/hooks/inventory/useInventoryMissingDataEbayItems";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";
import BulkEditEbayItemsModal from "./modals/BulkEditEbayItemsModal";

interface Column {
    key: string;
    label: string;
    render?: (value: any, item?: any) => React.ReactNode;
}

interface TableComponentProps {
    columns: Column[];
}

const InventoryMissingDataEbayItemsTableComponent: React.FC<TableComponentProps> = ({ columns }) => {
    const {
        data,
        loading,
        error,
        filters,
        setFilters,
        handleNextPage,
        handlePreviousPage,
        pageInfo,
        resetFilters,
    } = useInventoryMissingDataEbayItems();

    const { data: tagsData } = useQuery(GET_EBAY_ITEM_TAGS_QUERY);
    const { data: categoriesData } = useQuery(GET_EBAY_ITEM_CATEGORIES_QUERY);
    const { data: brandsData } = useQuery(GET_EBAY_ITEM_BRANDS_QUERY);
    const { data: sourceLocationsData } = useQuery(GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY);

    const [showFilters, setShowFilters] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [isBulkEditModalOpen, setBulkEditModalOpen] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilters(name, value === "" ? null : value);
    };

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };

    const handleCheckboxChange = (id: string) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(id)
                ? prevSelectedItems.filter((itemId) => itemId !== id)
                : [...prevSelectedItems, id]
        );
    };

    const openBulkEditModal = () => {
        setBulkEditModalOpen(true);
    };

    const closeBulkEditModal = () => {
        setBulkEditModalOpen(false);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const products = data?.inventoryMissingDataEbayItems?.edges || [];

    return (
        <div className="dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="flex justify-between items-center pb-2 px-2 sm:px-2 lg:px-1">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        Inventory with Missing Data
                    </h3>
                    <div className="flex space-x-2">
                        <button
                            onClick={resetFilters}
                            className="px-4 py-2 bg-gray-600 text-white dark:text-gray-900 dark:bg-gray-300 rounded-lg"
                        >
                            Reset Filters
                        </button>
                        <button
                            onClick={toggleFilters}
                            className="px-4 py-2 bg-white text-black border-black border dark:border-white dark:text-white dark:bg-gray-700 rounded-lg"
                        >
                            {showFilters ? "Hide Filters" : "Show Filters"}
                        </button>
                        <button
                            onClick={openBulkEditModal}
                            disabled={selectedItems.length === 0}
                            className={`px-4 py-2 ${selectedItems.length > 0 ? 'bg-blue-600 text-white' : 'bg-gray-400 text-gray-600'} rounded-lg`}
                        >
                            Bulk Edit
                        </button>
                    </div>
                </div>
                {showFilters && (
                    <div className="mt-4 px-2 sm:px-2 lg:px-1 pb-4">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            <select
                                name="tags"
                                value={filters.tags || ""}
                                onChange={handleInputChange}
                                className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                            >
                                <option value="">All Tags</option>
                                {tagsData?.ebayItemTags.map((tag: any) => (
                                    <option key={tag.id} value={tag.id}>
                                        {tag.name}
                                    </option>
                                ))}
                            </select>
                            <select
                                name="sourceLocationId"
                                value={filters.sourceLocationId || ""}
                                onChange={handleInputChange}
                                className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                            >
                                <option value="">All Source Locations</option>
                                {sourceLocationsData?.sourceLocations.map((location: any) => (
                                    <option key={location.id} value={location.id}>
                                        {location.name}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="number"
                                name="listedPriceLte"
                                placeholder="Max Listed Price"
                                defaultValue={filters.listedPriceLte || ""}
                                onBlur={handleInputChange}
                                className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                            />
                            <input
                                type="number"
                                name="listedPriceGte"
                                placeholder="Min Listed Price"
                                defaultValue={filters.listedPriceGte || ""}
                                onBlur={handleInputChange}
                                className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                            />
                            <select
                                name="itemCategoryId"
                                value={filters.itemCategoryId || ""}
                                onChange={handleInputChange}
                                className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                            >
                                <option value="">All Categories</option>
                                {categoriesData?.itemCategories.map((category: any) => (
                                    <option key={category.id} value={category.id}>
                                        {category.categoryName}
                                    </option>
                                ))}
                            </select>
                            <select
                                name="itemBrandId"
                                value={filters.itemBrandId || ""}
                                onChange={handleInputChange}
                                className="px-4 py-2 border rounded dark:bg-gray-700 dark:text-white"
                            >
                                <option value="">All Brands</option>
                                {brandsData?.brands.map((brand: any) => (
                                    <option key={brand.id} value={brand.id}>
                                        {brand.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                <div className="px-2 sm:px-2 lg:px-1">
                    <div className="flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y dark:divide-gray-700">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        if (isChecked) {
                                                            const allItemIds = products.map(({ node }: any) => decodeBase64Id(node.id));
                                                            setSelectedItems(allItemIds);
                                                        } else {
                                                            setSelectedItems([]);
                                                        }
                                                    }}
                                                    checked={products.length > 0 && selectedItems.length === products.length}
                                                />
                                            </th>
                                            {columns.map((col) => (
                                                <th
                                                    key={col.key}
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                                >
                                                    {col.label}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y dark:divide-gray-800">
                                        {products.map(({ node }: any) => {
                                            if (!node) return null;
                                            const itemId = decodeBase64Id(node.id);
                                            return (
                                                <tr key={node.id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(itemId)}
                                                            onChange={() => handleCheckboxChange(itemId)}
                                                        />
                                                    </td>
                                                    {columns.map((col) => (
                                                        <td
                                                            key={col.key}
                                                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0"
                                                        >
                                                            {col.render ? col.render(node[col.key], node) : node[col.key] ?? ''}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="mt-4">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={!pageInfo.hasPreviousPage}
                                        className="mr-2 px-4 py-2 bg-gray-300 text-gray-900 rounded"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={!pageInfo.hasNextPage}
                                        className="px-4 py-2 bg-gray-300 text-gray-900 rounded"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BulkEditEbayItemsModal
                    isOpen={isBulkEditModalOpen}
                    closeModal={closeBulkEditModal}
                    selectedProducts={selectedItems}
                />
            </div>
        </div>
    );
};

export default InventoryMissingDataEbayItemsTableComponent;
